@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/variables";

.abs-header-background {
    background-image: url('/assets/banner04.jpg');
    width: 100vw;
    min-height: 100vh;
    background-position: center;
    background-size: cover;

    @include media-breakpoint-down(sm) {

        background-image: url('/assets/mob.png');

    }
}

.abs-header-title {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 3.3rem;
    margin-top: 15vh;

    @include media-breakpoint-down(sm) {
        font-size: 2rem;
        padding: 20px;
        padding-bottom: 0;
        text-align: center;
    }

}

.abs-header-text {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
    margin-top: 15px;

    @include media-breakpoint-down(sm) {
        font-size: 1.2rem;
        padding: 20px;
        text-align: center;
    }

}

.abs-header-logo {
    height: 7vh;
    display: block;
    margin: auto;
    margin-top: 5vh;
}