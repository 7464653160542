@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/variables";

.abs-section2-container {
    width: 100vw;
    height: 45vh;
    @include media-breakpoint-down(md) {
        height: 90vh;
        display: none;
    }
}

.abs-section2-container-mobile {
    width: 100vw;
    display: none;
    @include media-breakpoint-down(md) {
        display: block;
    }
}

.abs-section2-content {
    display: inline-block;
    height: 45vh;
    vertical-align: top;

    @include media-breakpoint-down(md) {
        width: 100vw;
        height: auto;
    }
}

.abs-section2-content-image-mobile {
    display: inline-block;
    height: auto;
    padding-bottom: 5vh;
    vertical-align: top;
}

.abs-section2-content-image {
    display: inline-block;
    width: 40vw;
    height: 45vh;
    vertical-align: top;

    @include media-breakpoint-down(md) {
        width: 100vw;
        height: 80vh;
    }
}

.abs-section2-content-image-right {
    display: inline-block;
    width: 40vw;
    height: 45vh;
    margin-left: 10vw;
    vertical-align: top;

    @include media-breakpoint-down(md) {
        width: 100vw;
        height: 80vh;
    }
}

.abs-section2-image {
    background-position: 50%;
    background-size: cover;
}

.abs-image-center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    a {
        display: flex;
        height: 100%;
    align-items: center;
    justify-content: center;
    }
    
    img {
        width: 65%;
        @include media-breakpoint-down(md) {
            width: 35%;
        }
        @include media-breakpoint-down(sm) {
            width: 60%;
        }
    }
}


.abs-section2-content-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    height: 45vh;
    padding: 30px;
    padding-right: 150px;

    @include media-breakpoint-down(md) {
        width: 100vw;
        padding-right: 30px;
    }

    h2, h3, h4, p, h5 {
        color: #004F97;
        font-family: 'Poppins', sans-serif;
    }

    h4 {
        font-weight: 600;
        margin-bottom: 40px;
    }

    h5 {
        text-transform: uppercase;
        text-decoration: underline;
        margin-top: 40px;
        cursor: pointer;
    }

}

.abs-section2-content-text-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    padding: 30px;
    padding-right: 150px;
    text-align: center;

    @include media-breakpoint-down(md) {
        width: 100vw;
        padding-right: 30px;
    }

    h2, h3, h4, p, h5 {
        color: #004F97;
        font-family: 'Poppins', sans-serif;
    }

    h4 {
        font-weight: 600;
        margin-bottom: 40px;
    }

    h5 {
        text-transform: uppercase;
        text-decoration: underline;
        margin-top: 40px;
        cursor: pointer;
    }

}

.abs-section2-content-text-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    height: 45vh;
    padding: 30px;
    padding-left: 150px;

    @include media-breakpoint-down(md) {
        width: 100vw;
        padding-right: 30px;
    }

    h2, h3, h4, p, h5 {
        color: #004F97;
        font-family: 'Poppins', sans-serif;
    }

    h4 {
        font-weight: 600;
        margin-bottom: 40px;
    }

    h5 {
        margin-top: 40px;
        cursor: pointer;
    }

    text-align: right;
}

.right {
    padding-right: none;
}

.abs-section2-icon {
    margin-right: 20px;
}

.abs-section2-icon-right {
    margin-left: 20px;
}

.abs-section2-padding {
    padding-top: 10vh;
    padding-bottom: 10vh;
}

.abs-play-text {
    text-decoration: underline;
    cursor: pointer;
}

.abs-play-image {
    transition: filter 0.5s ease;
    transition: width 0.5s ease;
    cursor: pointer;
}

.abs-play-image:hover {
    -webkit-filter: drop-shadow(5px 5px 5px rgb(182, 182, 182));
    filter: drop-shadow(5px 5px 5px rgb(182, 182, 182));
    width: 70%;
}

.gray {
    padding-top: 20px;
    padding-bottom: 40px;
}

.abs-s2-title {
    text-align: center;
    color: #004F97;
        font-family: 'Poppins', sans-serif;
    font-weight: 700;
    margin-bottom: 40px;
}